import React, { useCallback, useState } from "react"

import Dialog from "."

type DialogContextType = {
    closeDialog: () => void, 
    setComponent: (cmp: React.ReactElement, backdrop?: React.ReactElement) => void
}

const DialogContext = React.createContext<DialogContextType>({
  closeDialog: () => {},
  setComponent: () => {},
})

export const DialogProvider: React.FunctionComponent = (props) => {
    const [component, setInnerComponent] = useState<React.ReactElement | undefined>()
    const [backdrop, setBackdrop] = useState<React.ReactElement | undefined>()

    const setComponent = useCallback((cmp, bckDrop) => {
      console.log("Setting backdrop", { bckDrop })
      setBackdrop(bckDrop)
      setInnerComponent(cmp)
    }, [component, backdrop])

    const closeDialog = useCallback(() => {
      setComponent(undefined, undefined)
    }, [setComponent])
  
    return (
      <DialogContext.Provider value={{ closeDialog, setComponent }} {...props} >
        {component && <Dialog onClose={closeDialog} backdrop={backdrop}>
            { component }
        </Dialog>}
        {props.children}
      </DialogContext.Provider>
    )
}



export const useDialog = (): DialogContextType => {
  const context = React.useContext(DialogContext)
  if (context === undefined) {
    throw new Error('useModal must be used within a UserProvider')
  }

  return context
}