
const breakpoints = {
    mobileBig: 321, // up(mobileBig) -> everything bigger than iPhone5
    tablet: 481, // up(tablet) -> iPad Mini 
    tabletBig: 769, // up(tabletBig) -> iPad 
    desktop: 1025, // up(desktop) -> usual desktopns, 1260, 1366 and such
    desktopBig: 1443, // we use it only for "work area" for the app for now, desktops with 1900
}


export const themes = {
    light: {
        colors: {
            main: '#000',
            secondary: '#999',
            hushed: '#333',
            bg: '#fff',
            bgGray: '#e5e5e5',
            bgService: '#F3F6FA',
            bgHushed: '#EBEFF5',
            lighterAccent: '#B1AFEC',
            lightGray: '#d5dde7', //! TOO
            shadeGray: '#b7bec8', //! MANY
            darkGray: '#48525f', // ! GRAY
            serviceGray: "#9CA5AF", // ! COLORS
            accent: '#625ed8',
            inactive: '#b9c4d1',
            error: '#C20114',

            onceSeparatorFaq: '#D5DDE7',
        },
        statuses: {
            1: '#FDB200',
            2: '#625ED8',
            3: '#67C87D',
        },
        breakpoints,
    },
}
